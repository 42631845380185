.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    &.fill-height {
      height: 100%;
      width: auto;
    }
  }
}
