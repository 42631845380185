@font-face {
  font-family: 'MiSans-Thin';
  src: url('/v2/font/MiSans-Thin.eot?#font-spider') format('embedded-opentype'), url('/v2/font/MiSans-Thin.woff2') format('woff2'), url('/v2/font/MiSans-Thin.woff') format('woff'),
    url('/v2/font/MiSans-Thin.ttf') format('truetype'), url('/v2/font/MiSans-Thin.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MiSans-Regular';
  src: url('/v2/font/MiSans-Regular.ttf') format('truetype');
}
