@import 'styles/var.scss';
@import 'styles/mixin.scss';
@import 'styles/font.scss';
$IMG_PATH: 'https://sdk-source.xylink.com/website/assets/home/';

// 首页置灰
.beGray {
  //页面置灰色
  filter: grayscale(100%); //IE浏览器
  -webkit-filter: grayscale(100%); //谷歌浏览器
  -moz-filter: grayscale(100%); //火狐
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1); //谷歌浏览器
}

%introBottomBorder {
  span {
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease-in-out;

    &:hover {
      border-color: $primaryColor;
    }
  }
}

// 头部banner幻灯片
.advertise {
  .advertiseItem {
    position: relative;
    overflow: hidden;
    height: pr(520);
    padding-left: 17.7vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: padding-left 0.5s ease-in-out;

    .content {
      padding-top: pr(140);
      font-family: 'MiSans';

      .title,
      .desc {
        font-size: pr(45);
        line-height: pr(65);
        font-weight: 500;
      }

      .intro {
        font-size: pr(20);
      }

      &.try {
        padding-top: pr(110);

        .intro {
          line-height: pr(26);
        }
      }

      &.whitePaper {
        .title {
          margin-bottom: pr(20);
        }

        .desc,
        .intro {
          font-size: pr(20);
          line-height: pr(36);
          font-weight: 400;
        }
      }

      &.harmony {
        .desc {
          font-weight: 300;
        }
      }
    }

    &.newProduct {
      background-image: url($IMG_PATH + 'PC/bannerBg0.jpg');
    }

    &.advantage {
      background-image: url($IMG_PATH + 'PC/bannerBg1.jpg');
    }

    &.try {
      background-image: url($IMG_PATH + 'PC/bannerBg2.jpg');
    }

    &.whitePaper {
      background-image: url($IMG_PATH + 'PC/bannerBg3.jpg');
    }

    &.harmony {
      background-image: url($IMG_PATH + 'PC/bannerBg4.jpg');
    }

    .btnWrapper {
      position: absolute;
      bottom: pr(165);
      width: 100%;
    }
  }

  .dots {
    bottom: pr(86);
    left: 17.7vw;
    z-index: 2;
    justify-content: flex-start;
    transition: left 0.5s ease-in-out;

    > li {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      width: pr(60);
      height: pr(20);
      margin: 0 10px 0 0;

      &:last-child {
        margin-right: 0;
      }

      &::before {
        position: absolute;
        left: 0;
        display: block;
        content: '';
        width: pr(60);
        height: 4px;
        background-color: rgba(62, 69, 76, 0.2);
        border-radius: 2px;
        transition: all 0.3s;
      }

      @media (any-hover: hover) {
        &:hover {
          cursor: pointer;

          &::before {
            background-color: rgba(62, 69, 76, 0.6);
          }

          button {
            background-color: transparent;
          }
        }
      }

      > button {
        height: 100%;
        background-color: transparent;
        border-radius: 2px;
      }
    }

    :global {
      li.slick-active {
        width: pr(60);

        button {
          background-color: transparent;
        }

        &:hover {
          button {
            background-color: transparent;
          }
        }

        &::after {
          position: absolute;
          left: 0;
          content: '';
          display: block;
          width: 0;
          height: 4px;
          border-radius: pr(4);
          background-color: $primaryColor;
          animation: progress 5s linear forwards;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .advertise {
    .advertiseItem {
      padding-left: 10vw;
    }

    .dots {
      left: 10vw;
    }
  }
}

@media (max-width: 767px) {
  .advertise {
    .advertiseItem {
      height: pr(880);
      padding-left: 0;
      text-align: center;

      .content {
        padding-top: pr(110);

        .title,
        .desc {
          font-size: pr(60);
          line-height: pr(80);
        }
      }

      &.newProduct {
        background-image: url($IMG_PATH + 'M/banner0.jpg');
      }

      &.advantage {
        background-image: url($IMG_PATH + 'M/banner1.jpg');
      }

      &.try {
        background-image: url($IMG_PATH + 'M/banner2.jpg');

        .content .intro {
          margin-top: pr(20);
          font-size: pr(30);
          line-height: pr(40);
        }
      }

      &.whitePaper {
        background-image: url($IMG_PATH + 'M/banner3.jpg');

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;

          .title {
            width: pr(600);
          }

          .desc,
          .intro {
            font-size: pr(24);
            line-height: pr(38);
          }
        }
      }

      &.harmony {
        background-image: url($IMG_PATH + 'M/banner4.jpg');

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: pr(80);

          .title {
            width: pr(420);
            margin-bottom: pr(10);
          }

          .desc {
            font-size: pr(50);
            line-height: pr(66);
          }
        }
      }

      .subImg {
        display: none;
      }

      .btnWrapper {
        bottom: pr(100);
        flex-direction: column;

        .btnTry {
          margin-right: 0;
          margin-bottom: pr(16);
        }
      }
    }

    .dots {
      bottom: pr(40);
      left: 0;
      justify-content: center;
    }
  }
}

// 点击视频，了解小鱼易连
.videoWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: pr(520);
  background-image: url(https://testdevcdn.xylink.com/h5/sdk_doc/static/website/f6934c353137328c363c96e836af8f08ba2c7a608446df33a20fd9f7d40461ed.jpg);
  background-size: 100%;

  .shadow {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .subImg {
    position: absolute;
    z-index: 2;
    top: pr(170);
    left: 0;
    @extend %flex-column-center;
    width: 100%;

    .title {
      font-size: pr(44);
      line-height: pr(62);
      color: #fff;
      font-weight: 300;
    }

    .playBtn {
      width: pr(52);
      height: pr(52);
      margin-top: pr(40);
      cursor: pointer;
    }
  }
}

.videoModal {
  width: 54% !important;
  max-width: 100%;
  margin: 0;

  .videoPlayer {
    width: 100%;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
    opacity: 0.5;
    margin-top: 12px;
    margin-right: 14px;
    display: inline-block;

    &:hover {
      opacity: 1;
    }
  }

  :global {
    .ant-modal-close-x {
      width: 48px;
      height: 48px;
      text-align: right;
      overflow: hidden;
    }

    .ant-modal-header {
      background: #000;
      height: 48px;
      border-bottom: none;
    }

    .ant-modal-body {
      padding: 0;
      line-height: 1;
      font-size: 0;
      background: #000;
    }
  }
}

@media (max-width: 767px) {
  .videoWrapper {
    height: pr(640);
    background-image: url('https://testdevcdn.xylink.com/h5/sdk_doc/static/website/e3ef57185309f0687d5d632e10849ba0df937918a2f6a2c20ba89c7a60c8e0f5.jpg');
    background-size: 100% 100%;

    .subImg {
      top: pr(175);

      .title {
        height: pr(62);
        font-size: pr(44);
        font-weight: normal;
        color: $bg-normal-color;
      }

      .playBtn {
        width: pr(86);
        height: pr(86);
      }
    }
  }

  .videoModal {
    width: 100% !important;
  }
}

// 核心介绍模块
.coreIntroductions {
  @extend %flex-column-align-center;
  justify-content: flex-start;
  height: pr(1669);
  padding-top: pr(110);
  background: url($IMG_PATH + 'PC/core_bg.jpg') no-repeat 50%;
  background-size: cover;

  .coreIntroduction {
    @extend %flex-column-align-center;
    justify-content: flex-start;
    width: pr(1240);
    height: pr(450);
    border-radius: pr(4);
    line-height: 1;
    background-repeat: no-repeat;
    background-size: cover;

    .title {
      margin: pr(70) 0 pr(24);
      font-size: pr(40);
      font-weight: normal;
      color: #fff;
    }

    .desc {
      font-size: pr(18);
      font-weight: 500;
      color: $primaryColor;
      @extend %introBottomBorder;
    }

    &.hardware {
      margin: pr(24) 0;

      .title {
        color: $font-color;
      }
    }
  }
}

@media (max-width: 767px) {
  .coreIntroductions {
    height: pr(1879);
    padding: pr(100) pr(60) pr(120);
    background: url($IMG_PATH + 'M/core_bg.jpg') no-repeat 50%;
    background-size: cover;

    .coreIntroduction {
      width: pr(630);
      height: pr(537);
      border-radius: pr(4);

      .title {
        margin: pr(105) 0 pr(38);
        font-size: pr(44);
        line-height: pr(40);
      }

      .desc {
        font-size: pr(28);
        line-height: pr(18);
      }

      &:nth-child(2) {
        margin: pr(24) 0;
      }
    }
  }
}

// 地图模块
.mapWrapper {
  position: relative;
  height: pr(550);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contentWrapper {
    position: absolute;
    top: 0;
    left: 17.7vw;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: left 0.5s ease-in-out;

    .numberContent {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      width: pr(398);
      height: pr(345);
      margin-right: 7.4vw;
      padding: pr(45) pr(30);
      border-radius: pr(6);
      background: linear-gradient(326.16deg, rgba(75, 201, 255, 0.124) 2.27%, rgba(24, 20, 213, 0.324) 96.11%);

      .numberItem {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #fff;
        width: pr(140);

        .number {
          margin-bottom: pr(9);
          font-size: pr(30);
          line-height: pr(30);
          font-weight: bold;
        }

        .text {
          margin-bottom: pr(45);
          font-size: pr(14);
          line-height: pr(16);
        }

        &.finance_banks,
        &.enterprises,
        &.hours {
          width: pr(154);
        }
      }
    }

    .textContent {
      height: 100%;
      padding-top: pr(223);
      font-size: pr(30);
      line-height: pr(50);
      color: #fff;
    }
  }

  @media (max-width: 1240px) {
    .contentWrapper {
      justify-content: center;
      left: 0;

      .numberContent {
        margin-right: pr(123);
      }
    }
  }
}

@media (max-width: 767px) {
  .mapWrapper {
    height: pr(970);

    .mapContent {
      position: relative;
      height: 100%;

      .mapImg {
        width: 100%;
        height: 100%;
      }

      .mapText {
        position: absolute;
        top: pr(143);
        left: pr(95);
        font-size: pr(40);
        line-height: pr(70);
        text-align: center;
        color: #fff;
      }
    }

    .contentWrapper {
      align-items: flex-end;

      .numberContent {
        position: static;
        width: pr(630);
        height: pr(360);
        padding: pr(45) pr(80) pr(45) pr(60);
        margin-bottom: pr(108);
        margin-right: 0;
        border-radius: pr(10);

        .numberItem {
          width: pr(200);

          .number {
            margin-bottom: pr(15);
            font-size: pr(44);
            line-height: pr(30);
          }

          .text {
            margin-bottom: pr(44);
            font-size: pr(20);
            line-height: pr(16);
            font-weight: 400;
          }

          &.finance_banks,
          &.enterprises,
          &.hours {
            width: pr(220);
          }
        }
      }
    }
  }
}

// 行业解决方案
.solWrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: pr(654);
  margin: 0 auto;

  .bigTitle {
    margin: pr(100) 0 pr(80);
    font-size: pr(44);
    line-height: pr(44);
    font-weight: normal;
    color: $font-color;
  }

  .contentWrapper {
    display: flex;
    width: pr(1240);

    .solution {
      position: relative;
      overflow: hidden;
      display: flex;
      flex: 1;
      height: pr(330);
      background-position: center;
      background-size: cover;
      transition: flex 0.6s ease-in-out;
      cursor: pointer;

      .itemContentWrapper {
        width: 100%;

        .content {
          position: relative;
          z-index: 2;
          width: 100%;
          height: 100%;

          .intro {
            padding: pr(33) 0 0 pr(30);
            color: $font-color;
            transition: padding 0.5s ease-in-out;

            .title {
              font-size: pr(26);
              line-height: pr(24);
            }

            .desc,
            .jumpIntro {
              opacity: 0;
              transition: opacity 0.6s ease-in-out;
              @extend %introBottomBorder;

              span {
                &:hover {
                  border-color: #fff;
                }
              }
            }

            .desc {
              margin-top: pr(26);
              font-size: pr(16);
              line-height: pr(24);
            }

            .jumpIntro {
              position: absolute;
              bottom: pr(34);
              z-index: 2;
              color: #fff;
              font-size: pr(18);
              line-height: pr(24);
              font-weight: 500;
            }
          }

          .arrow {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: opacity 0.7s ease-in-out;
          }
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: opacity 0.5s ease-in-out;
      }

      &::before {
        background-image: var(--close-image-url);
        opacity: 1;
      }

      &::after {
        background-image: var(--open-image-url);
        opacity: 0;
      }

      &.active,
      &.initialActive {
        flex: 2;

        .itemContentWrapper {
          .content {
            .intro {
              padding-left: pr(35);

              .desc,
              .jumpIntro {
                opacity: 1;
              }
            }

            .arrow {
              @keyframes fly {
                from {
                  left: pr(-330);
                  opacity: 0;
                }

                50% {
                  opacity: 0.3;
                }

                to {
                  left: pr(0);
                  opacity: 1;
                }
              }

              animation: fly 0.7s ease-in-out;
              animation-fill-mode: both;
            }
          }
        }

        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .solWrapper {
    height: pr(2020);

    .bigTitle {
      margin: pr(100) 0 pr(60);
      font-size: pr(44);
      line-height: 1;
    }

    .contentWrapper {
      flex-direction: column;
      width: pr(630);

      .solution {
        flex: auto;
        height: pr(290);

        .itemContentWrapper {
          .content {
            .intro {
              padding: pr(50) 0 0 pr(40);
              font-weight: 500;

              .title {
                font-size: pr(40);
                line-height: pr(44);
              }

              .desc {
                margin-top: pr(20);
                opacity: 1;
                font-size: pr(28);
                line-height: 1;
                font-weight: 400;
              }

              .jumpIntro {
                bottom: pr(44);
                right: pr(30);
                opacity: 1;
                font-size: pr(28);
                line-height: pr(24);
              }
            }
          }
        }
      }
    }
  }
}

// 新闻模块
.newsWrapper {
  height: pr(717);
  padding-top: pr(100);
  line-height: 1;
  background-color: #f8f9fc;

  header {
    text-align: center;

    .title {
      color: $font-color;
      font-size: pr(44);
    }

    .subTitle {
      margin: pr(24) 0 pr(80);
      font-size: pr(18);
      color: $primaryColor;
      @extend %introBottomBorder;
    }
  }

  .inner {
    width: 100%;
    max-width: pr(1240);
    margin: 0 auto;
  }

  .news {
    position: relative;
    background: $bg-normal-color;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;

    .poster {
      height: pr(260);
      width: 100%;
      border-radius: pr(4) pr(4) 0 0;
      transition: height 0.3s ease-in-out;
    }

    .detail {
      position: absolute;
      bottom: pr(-95);
      z-index: 2;
      width: 100%;
      height: pr(95);
      padding: pr(24) pr(18);
      box-shadow: 0px 4px 10px 0px rgba(2, 32, 48, 0.15);
      background-color: #fff;
      border-radius: 0 0 pr(4) pr(4);
      transition: all 0.4s ease-in-out;

      .title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        text-overflow: ellipsis;
        font-size: pr(18);
        line-height: pr(24);
        color: $font-color;
      }

      .jumpDetails {
        position: absolute;
        bottom: pr(28);
        right: pr(28);
        font-size: pr(18);
        line-height: 1;
        color: $primaryColor;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        @extend %introBottomBorder;
      }
    }

    @media (any-hover: hover) {
      &:hover {
        transform: translate(0, -10px);

        .detail {
          height: pr(135);

          .jumpDetails {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .newsWrapper {
    height: pr(2106);
    padding: pr(100) pr(60) 0;

    header {
      line-height: 1;

      .title {
        font-size: pr(44);
      }

      .subTitle {
        margin: pr(24) 0 pr(50);
        font-size: pr(28);
      }
    }

    .inner {
      width: 100%;
    }

    .news {
      margin-bottom: pr(30);

      .poster {
        height: pr(410);
      }

      .detail {
        position: static;
        padding: pr(36) pr(35);
        height: pr(150);

        .title {
          font-size: pr(28);
          line-height: pr(40);
        }
      }
    }
  }
}

// 合作伙伴
.partnerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    padding: pr(100) 0 pr(80);
    text-align: center;
    font-size: pr(44);
    line-height: 1;
  }

  .partnersImg {
    width: pr(1240);
  }
}

@media (max-width: 767px) {
  .partnerWrapper {
    padding: 0 pr(25);

    .title {
      padding: pr(100) 0 pr(48);
    }

    .partnersImg {
      width: pr(700);
    }
  }
}
