@import 'styles/mixin.scss';
@import 'styles/var.scss';

// 底部按钮
.btnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: pr(100) 0 pr(120);

  :global {
    .btnTry {
      margin-right: 28px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin: pr(140) 0 pr(180);

    :global {
      .btnTry {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
}
