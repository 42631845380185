.fade {
  opacity: 0;
  &.fadeIn {
    animation: In 0.8s forwards;
  }
  @keyframes In {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
